<template>
  <v-container v-if="fintech">
    <h1>{{fintech.name}}</h1>
    <v-row>
      <v-col>
      <template v-if="userIsCreator">
        <v-spacer></v-spacer>
        <app-edit-fintech-details-dialog :fintech="fintech">
        </app-edit-fintech-details-dialog>
      </template>
      <template>
        {{fintech.votes}} VOTES
      <vote-dialog :fintechId="fintech.id">
      </vote-dialog>
      </template>
      <v-card
      width="350px">
        <v-img
          :src="fintech.image">
        </v-img>
      </v-card>  
     </v-col>

     <v-col>
      <h2>Go To {{fintech.name}}</h2>
      <v-card
    
      max-width="344"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title >
              {{fintech.name}} is located in
            </v-list-item-title>
            <v-list-item-subtitle>{{fintech.location}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn rounded class="primary" :href="fintech.url">
            <v-icon>mdi-web</v-icon>
            Visit {{fintech.name}}'s website
           </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <h2>Countries where {{fintech.name}} is available</h2>
      <v-card
      height="300px"
      width="450px">
      <MapChart
      :countryData="countries"
      highColor="#0B84F6"
      lowColor="#03bafc"
      countryStrokeColor="#FFFFFF"
      defaultCountryFillColor="#f2f2f2"
      showLegend='false'
      
      />
      </v-card>
      <!--
      <v-chip
          v-for="(country, index) in fintech.countries"
          :key="index">
           {{country}}
        </v-chip>
      -->
      
    </v-col>
  
    <v-col>
      <h2>What is {{fintech.name}}</h2>
        <v-card>
          <v-card-text>
            {{fintech.short_des}}
          </v-card-text>
        </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
       <h2>What you can do with {{fintech.name}}</h2>
       <div >
         <v-chip-group
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="(category, index) in fintech.categories"
              :key="index"
              :to="'/categories/'+category">
               {{category}}

            </v-chip>
          </v-chip-group>
      </div>
    </v-col>
    <v-col v-if="fintech.platforms != undefined">
      <h2>Available Platforms for {{fintech.name}}</h2>
       <v-chip-group
            active-class="primary--text"
            column
          >
        <v-chip
            v-for="(platform, index) in fintech.platforms"
            :key="index"
            >
             {{platform}}
        </v-chip>
      </v-chip-group>
    </v-col>
    <v-col v-if="fintech.currencies != undefined">
      <h2>Available Currencies in {{fintech.name}}</h2>
       <v-chip-group
            active-class="primary--text"
            column
          >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
                v-for="(currency, index) in fintech.currencies"
                :key="index"
                v-bind="attrs"
                v-on="on"
                >
                 {{currency}}
            </v-chip>
          </template>
          <span>Country's currency</span>
        </v-tooltip>
      </v-chip-group>
      
    </v-col>
    </v-row>
    <v-row>
    <v-col v-if="openSlide">
      <!-- v-if="fintech.categories != undefined" -->
     <h2>More pictures from {{fintech.name}}</h2>
      <v-sheet
        class="mx-auto"
        elevation="8"
        
      >
        <v-slide-group
          v-model="openSlide"
          class="pa-4"
          show-arrows
        >
          <v-slide-item
            v-for="n in 15"
            :key="n"
            v-slot="{ active, toggle }"
          >
            <v-card
              :color="active ? 'primary' : 'grey lighten-1'"
              class="ma-4"
              height="200"
              width="100"
              @click="toggle"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-scale-transition>
                  <v-icon
                    v-if="active"
                    color="white"
                    size="48"
                    v-text="'mdi-close-circle-outline'"
                  ></v-icon>
                </v-scale-transition>
              
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>

        <v-expand-transition>
          <v-sheet
            v-if="model != null"
            height="200"
            tile
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <h3 class="title">
                Selected {{ model }}
              </h3>
            </v-row>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
  </v-col>

</v-row>
</v-container>
</template>

<script>
  import MapChart from 'vue-map-chart'

export default {
  name: 'SingleFintech',
  components: {
    MapChart
  },
  props: ['fintechid'],
  data() {
  return {
  openSlide: null
  //   // metaInfo: [
  //   //             { name: 'description', content:  fintech.short_des},
  //   //             { property: 'og:title', content: "Epiloge - Find the best Fintech for you"},
  //   //             { property: 'og:site_name', content: 'Epiloge'},
  //   //             { property: 'og:image', content: fintech.image},
  //   //             {property: 'og:type', content: 'website'},    
  //   //             {name: 'robots', content: 'index,follow'} 
  //   //         ]
  }
  },
  computed: {
      fintech () {
        return this.$store.getters.loadedFintech(this.fintechid)
      },
      userIsAuthenticated () {
        return this.$store.getters.user !== null && this.$store.getters.user !== undefined
      },
      userIsCreator () {
        if (!this.userIsAuthenticated) {
          return false
        }
        return this.$store.getters.user.id === this.fintech.creatorId
      },
      loading () {
        return this.$store.getters.loading
      },

      countries () {
      let _countriesout = {}
      let _countriesin = this.$store.getters.loadedFintech(this.fintechid).countries
      //  if (this.$store.getters.loadedFintech(this.fintechid).countries === countryData.name){
          
       // }
       // return
      //}
      //"{'US': 100, 'CA': 100, 'GB': 100, 'IE': 100, 'ES': 100, 'SG' : 100, 'DE' : 100}"
      for ( var i = 0; i < _countriesin.length; i++){
        
        _countriesout[_countriesin[i]] =  100
        // _countriesout.push(_country)
      }
       /* eslint-disable no-console */
       //console.log(_countriesin)
      //console.log(_countriesout)
      /* eslint-enable no-console */
      return _countriesout
      
      }
    }
}
</script>

<style lang="scss" scoped></style>
